import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Action, Store, createSelector } from '@ngrx/store';
import { isObject } from 'lodash-es';
import { filter, map, mergeMap, withLatestFrom } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseByModule, responseRequestType } from 'src/app/core/api/api.utilities';
import { EWalletAuthorizeResponse } from 'src/app/core/api/responses/ewallet-authorize';
import { PaymentFailed, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectGooglePayCards } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { setAuthorizingAction } from 'src/app/core/session/session.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import {
  ERROR_CODE_EWALLET_AUTHORIZE_PAYMENT_DECLINED,
  ERROR_CODE_RESPONSE_NOT_OBJECT,
  ERROR_CODE_SERVICE_FAILED,
} from 'src/app/shared/enums/error-code.enums';
import { selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { validString } from 'src/app/shared/utilities/types.utils';
import * as ExtrasAction from '../../extras/extras.actions';
import { isFreedomPay } from '../../freedompay/freedompay.utils';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { sendPaymentFailed, updateGooglePayAvailabilityAction } from '../billing.actions';
import {
  giftCardSplitPaymentWithGooglePaySuccessAction,
  initializeGiftCardSplitPaymentWithGooglePayAction,
  initiateGooglePayEWalletAuthorizationAction,
} from './google-pay.actions';
import { GooglePayService } from './google-pay.service';

const { GOOGLE_PAY } = PaymentType;
@Injectable()
export class GooglePayEffects {
  /**
   * Handles making payment with google pay
   */
  onEWalletAuthorize$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initiateGooglePayEWalletAuthorizationAction),
      withLatestFrom(
        this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
      ),
      mergeMap(([action, currState]) => {
        const actions: Action[] = [setAuthorizingAction({ authorizing: true })];

        if (currState.giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(currState.giftCardState.giftcards)) {
          actions.push(initializeGiftCardSplitPaymentWithGooglePayAction({ amount: currState.totalAmount, payload: action.payload }));
        } else {
          const { eWalletRequest, callbackData } = action.payload;
          actions.push(
            postApiRequest({
              requestType: ApiRequestType.EWALLET_AUTHORIZE,
              body: eWalletRequest,
              params: { module: GOOGLE_PAY },
              callbackData,
            })
          );
        }

        return actions;
      })
    )
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(giftCardSplitPaymentWithGooglePaySuccessAction),
      map((action) => {
        const { eWalletRequest, callbackData } = action.payload;
        return postApiRequest({
          requestType: ApiRequestType.EWALLET_AUTHORIZE,
          body: eWalletRequest,
          params: { module: GOOGLE_PAY },
          callbackData,
        });
      })
    )
  );

  /**
   * Handles eWallet autorization
   */
  onEWalletAuthorizeResponse$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(apiResponse),
        filter(responseRequestType(ApiRequestType.EWALLET_AUTHORIZE)),
        filter(responseByModule(GOOGLE_PAY)),
        map((action) => {
          if (action.isOk) {
            this.googlePayService.handleAuthorizeResponse(action.callbackData, action.response);
          } else {
            const payload: PaymentFailed = { paymentType: PaymentType.GOOGLE_PAY };
            const response: EWalletAuthorizeResponse = action.response;
            if (!isObject(response)) {
              payload.errorCode = ERROR_CODE_RESPONSE_NOT_OBJECT;
              payload.reason = 'Back response is invalid';
              payload.details = typeof response;
            } else if (validString(response.error_msg)) {
              payload.errorCode = ERROR_CODE_SERVICE_FAILED;
              payload.reason = response.error_msg;
            } else {
              payload.errorCode = ERROR_CODE_EWALLET_AUTHORIZE_PAYMENT_DECLINED;
              payload.details = response.approval_code;
              payload.reason = response.approval_text;
            }

            [
              sendAnalyticsEventAction({
                action: 'pay',
                category: 'google pay',
                label: 'payment failed',
                nonInteraction: true,
              }),
              sendPaymentFailed({ payload }),
              showNotificationAction({
                buttonLabel: this.localeService.get('common.close'),
                dialogType: NotificationDialogType.GENERAL,
                initiator: 'google pay authorize failure',
                message: this.localeService.get('googlepay.redirectfailure'),
              }),
              hidePageSpinner({ initiator: 'google pay authorize failure' }),
              routeTo({
                payload: ['select'],
              }),
            ].forEach((actn) => this.store.dispatch(actn));
          }
        })
      ),
    { dispatch: false }
  );

  /**
   * Handles actions to display payment options based on the ticket protection selection
   */
  updateTicketProtectionStatusAction$ = createEffect(() =>
    this.actions$.pipe(
      ofType(ExtrasAction.updateTicketProtectionStatusAction),
      withLatestFrom(this.store.select(selectGooglePayCards)),
      filter(([_, googlePayCards]) => isFreedomPay(googlePayCards)),
      map(([action]) => {
        this.googlePayService.setAvailability(!action.payload);
        return updateGooglePayAvailabilityAction({ status: !action.payload });
      })
    )
  );

  constructor(private store: Store<AppState>, private actions$: Actions, private googlePayService: GooglePayService, private localeService: LocaleService) {}
}
