import { createAction, props } from '@ngrx/store';
import { InsuranceAuthResponse } from 'src/app/core/api/responses/authorize';
import { UpdateAltPayResponse } from 'src/app/core/api/responses/update-alt-pay';
import { DonationAuthResponse } from 'src/app/core/application-bridge/application-bridge.models';
import { InitiateTmbThanachartPayload } from './tmbthanachart.config';

export enum TmbThanachartActionTypes {
  InitiateTmbThanachart = '[TmbThanachart] Initiate payment',
  CheckPaymentStatus = '[TmbThanachart] Check Payment Status',
  PollPaymentStatus = '[TmbThanachart] Poll Payment Status',
  StopPolling = '[TmbThanachart] Stop Polling Payment Status',
  SendPaymentCompletePending = '[TmbThanachart] Send Payment Complete: Pending Status',
  HandlePollTimeout = '[TmbThanachart] Handle Poll Timeout',
}

/**
 * The action to initiate transaction
 */
export const initiateTmbThanachartAction = createAction(TmbThanachartActionTypes.InitiateTmbThanachart, props<{ payload: InitiateTmbThanachartPayload }>());
/**
 * The action to send payment complete event
 */
export const sendTTBPaymentPending = createAction(
  TmbThanachartActionTypes.SendPaymentCompletePending,
  props<{
    payload: {
      amount: number;
      authId: number;
      paymentReference: string;
      donation?: DonationAuthResponse;
      insurance?: InsuranceAuthResponse;
    };
  }>()
);
/**
 * The action to initiate transaction
 */
export const checkPaymentStatusAction = createAction(TmbThanachartActionTypes.CheckPaymentStatus, props<{ payload: InitiateTmbThanachartPayload }>());
/**
 * The action to poll payment status
 */
export const pollPaymentStatusAction = createAction(TmbThanachartActionTypes.PollPaymentStatus, props<{ reqBody; payload: InitiateTmbThanachartPayload }>());
/**
 * The action to stop polling payment status
 */
export const stopPollingAction = createAction(TmbThanachartActionTypes.StopPolling, props<{ payload: UpdateAltPayResponse | InitiateTmbThanachartPayload }>());
/**
 * The action to stop polling payment status
 */
export const handlePollTimeoutAction = createAction(TmbThanachartActionTypes.HandlePollTimeout, props<{ payload: InitiateTmbThanachartPayload }>());
