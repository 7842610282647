import { createFeatureSelector, createSelector } from '@ngrx/store';
import { validArray } from 'src/app/shared/utilities/types.utils';
import { PaymentMethod } from '../api/responses/get-payment-configuration';
import { AlternatePayment, ConfiguredPayments, CreditCard, GiftCardPayment } from './payment-configuration.model';
import { PaymentConfigurationState } from './payment-configuration.state';

/**
 * Selects the entire payment configuration state
 * @param state The app state
 * @returns The payment configuration state
 */
export const selectPaymentConfiguration = createFeatureSelector<PaymentConfigurationState>('paymentConfiguration');

/**
 * Selects the entire merchant state
 * @param state The app state
 * @returns The merchant payment configuration state
 */
export const selectConfiguredPayments = createSelector(
  selectPaymentConfiguration,
  ({ configuredPayments }): ConfiguredPayments => configuredPayments ?? <ConfiguredPayments>{}
);

/**
 * Selects the merchant alternate payment types
 * @param state The app state
 * @returns The merchant alternate payment configurations
 */
export const selectAlternatePayments = createSelector(selectConfiguredPayments, (state: ConfiguredPayments): AlternatePayment[] => state?.alternatePayments);

/**
 * Returns credit cards for the merchant
 */
export const selectCreditCards = createSelector(selectConfiguredPayments, (state: ConfiguredPayments): CreditCard[] => {
  return state ? state.creditCards : [];
});

/**
 * Find out if we have apple pay configured
 */
export const selectApplePayCards = createSelector(selectConfiguredPayments, (state: ConfiguredPayments): PaymentMethod[] => {
  return state?.applePayCards;
});

/**
 * Find out if we have google pay configured
 */
export const selectGooglePayCards = createSelector(selectConfiguredPayments, (state: ConfiguredPayments): PaymentMethod[] => {
  return state?.googlePayCards;
});

/**
 * Selects the gift card configured
 */
export const selectGiftCard = createSelector(selectConfiguredPayments, (state: ConfiguredPayments): GiftCardPayment => {
  return (state && state.giftCard) || <GiftCardPayment>{};
});

/**
 * Selects payment merchant id from configured payments
 */
export const selectPaymentMerchantId = createSelector(selectConfiguredPayments, (state: ConfiguredPayments): number => {
  return state && Object.values(state).find(validArray)?.[0]?.paymentMerchantId;
});
