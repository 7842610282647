import { Injectable } from '@angular/core';
import Bugsnag from '@bugsnag/js';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { Store, createSelector } from '@ngrx/store';
import { filter, map, mergeMap, tap, withLatestFrom } from 'rxjs';
import { AppState } from 'src/app/app.state';
import { sendAnalyticsEventAction } from 'src/app/core/analytics/analytics.actions';
import { apiResponse, postApiRequest } from 'src/app/core/api/api.actions';
import { responseRequestType } from 'src/app/core/api/api.utilities';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { AuthorizedPaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { getErrorLocaleFromResponse } from 'src/app/core/notification/notification.utils';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { PaymentMethodCode, PaymentProviderType } from 'src/app/core/payment-configuration/payment-configuration.model';
import { routeTo } from 'src/app/core/routing/routing.actions';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_CANCELLED } from 'src/app/shared/enums/application-bridge.enums';
import { APP_CONFIG_DEFAULT_BILLING_VALUE } from 'src/app/shared/enums/application-config.enum';
import { BillingDefaultValue } from 'src/app/shared/enums/billing.enums';
import { selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';
import { returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validObject } from 'src/app/shared/utilities/types.utils';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { gatherPaymentDetailsForAmazonPayAction, sendPaymentFailed, updateBillingDemographicsAction } from '../billing.actions';
import { ApplicationConfigs } from './../../../core/application-config/application-config.state';
import {
  cancelAmazonPaySessionAction,
  completeAmazonPaySessionAction,
  giftCardSplitPaymentWithAmazonPaySuccessAction,
  initializeAmazonPayAction,
  initializeGiftCardSplitPaymentWithAmazonPayAction,
  loadAmazonPaySessionAction,
  mountAmazonPayButtonAction,
  openAmazonPayWindowAction,
} from './amazon-pay.actions';
import { AmazonPayService } from './amazon-pay.service';

@Injectable()
export class AmazonPayEffects {
  /**
   * Initiate Amazon Pay session
   */
  initializeAmazonPay$ = createEffect(() =>
    this.actions$.pipe(
      ofType(initializeAmazonPayAction),
      map(({ payload }) => postApiRequest({ requestType: ApiRequestType.AMAZON_PAY_GENERATE_SESSION, body: payload }))
    )
  );

  /**
   * Mounts Amazon Pay to DOM
   */
  mountAmazonPay$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(mountAmazonPayButtonAction),
        map((action) => this.amazonPayService.renderAmazonPayBtn(action.elementId))
      ),
    { dispatch: false }
  );

  /**
   * Opens new window for Amazon Pay
   */
  openAmazonPayWindow$ = createEffect(() =>
    this.actions$.pipe(
      ofType(openAmazonPayWindowAction),
      map(() => {
        this.amazonPayService.openAmazonPayWindow();
        return showPageSpinner({ initiator: 'Amazon Pay: open payment window' });
      })
    )
  );

  /**
   * Handles amazon pay new session response
   */
  generateAmazonPaySessionResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.AMAZON_PAY_GENERATE_SESSION)),
      tap(({ isOk, response }) => {
        this.amazonPayService.sessionInfo = isOk ? response : null;
      }),
      mergeMap(({ isOk, response }) => {
        if (isOk) {
          this.amazonPayService.setPaymentReference(response.paymentReference);
          return [loadAmazonPaySessionAction()];
        } else {
          this.amazonPayService.closeWindow();

          Bugsnag.notify(new Error(`AmazonPay Session failed to initialize`), (event) => {
            event.addMetadata('AmazonPay', {
              message: response?.error_msg ?? 'unknown',
            });
          });

          const errorMsg = getErrorLocaleFromResponse(this.localeService, response);
          const initiator = 'Amazon Pay: payment initiation error';

          return [
            sendPaymentFailed({ payload: { paymentType: PaymentType.AMAZON_PAY } }),
            hidePageSpinner({ initiator }),
            showNotificationAction({
              buttonLabel: this.localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator,
              message: errorMsg,
            }),
          ];
        }
      })
    )
  );

  /**
   * Handles payment cancel event for amazon pay session
   */
  cancelAmazonPaySession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(cancelAmazonPaySessionAction),
      mergeMap(() => [
        sendAnalyticsEventAction({
          action: 'click',
          category: PaymentType.AMAZON_PAY,
          label: 'payment canceled',
        }),
        sendMessageAction({ key: EVENT_PAYMENT_CANCELLED }),
        hidePageSpinner({ initiator: 'Amazon Pay: payment cancelled' }),
      ])
    )
  );

  /**
   * Loads session data to Amazon Pay window
   */
  loadAmazonPaySession$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType(loadAmazonPaySessionAction),
        tap(() => {
          this.amazonPayService.loadDataToAmazonPayWindow();
        })
      ),
    { dispatch: false }
  );

  /**
   * Completes split payment
   */
  completeSplitPayment$ = createEffect(() =>
    this.actions$.pipe(
      ofType(giftCardSplitPaymentWithAmazonPaySuccessAction),
      map((action) => {
        const body = {
          amount: action.payload.amount,
          checkout_session_id: action.payload.checkout_session_id,
          payment_reference: action.payload.payment_reference,
          merchant_id: action.payload.merchant_id,
        };
        return postApiRequest({
          requestType: ApiRequestType.AMAZON_PAY_COMPLETE_SESSION,
          body,
        });
      })
    )
  );

  /**
   * Handles amazon pay auth completion
   */
  completeAmazonPaySession$ = createEffect(() =>
    this.actions$.pipe(
      ofType(completeAmazonPaySessionAction),
      withLatestFrom(
        this.store.select(createSelector(selectGiftCardState, selectTotalAmount, (giftCardState, totalAmount) => ({ giftCardState, totalAmount })))
      ),
      map(([{ payload }, currState]) => {
        if (currState.giftCardState.totalBalance > 0 && !hasPreAuthGiftCards(currState.giftCardState.giftcards)) {
          return initializeGiftCardSplitPaymentWithAmazonPayAction({
            amount: currState.totalAmount,
            payload: {
              auth_id: payload.authId,
              amount: payload.amount,
              merchant_id: payload.merchantId,
              checkout_session_id: payload.checkoutSessionId,
              payment_reference: payload.paymentReference,
            },
          });
        } else {
          return postApiRequest({
            requestType: ApiRequestType.AMAZON_PAY_COMPLETE_SESSION,
            body: {
              amount: payload.amount,
              checkout_session_id: payload.checkoutSessionId,
              payment_reference: payload.paymentReference,
              merchant_id: payload.merchantId,
            },
          });
        }
      })
    )
  );

  /**
   * Handles amazon pay auth completion response
   */
  completeAmazonPaySessionResponse$ = createEffect(() =>
    this.actions$.pipe(
      ofType(apiResponse),
      filter(responseRequestType(ApiRequestType.AMAZON_PAY_COMPLETE_SESSION)),
      withLatestFrom(
        this.store.select(createSelector(selectAppConfig([APP_CONFIG_DEFAULT_BILLING_VALUE]), (appConfig) => ({ ...(appConfig as ApplicationConfigs) })))
      ),
      mergeMap(([{ isOk, response, body }, { [APP_CONFIG_DEFAULT_BILLING_VALUE]: defaultBillingConfig = {} }]) => {
        if (isOk) {
          const paymentDetails: AuthorizedPaymentInfo = {
            method: PaymentType.AMAZON_PAY,
            authId: response.payment_reference,
            legacy: {
              authId: response.auth_id,
              paymentMerchantId: body?.merchant_id,
              paymentMethodCode: PaymentMethodCode.AMAZON_PAY,
              paymentProviderName: PaymentProviderType.AmazonPay,
            },
          };

          if (validObject(response?.donation)) {
            paymentDetails.donation = convertDonationResponse(response.donation);
          }
          if (validObject(response?.insurance)) {
            paymentDetails.insurance = convertInsuranceResponse(response.insurance);
          }

          return [
            hidePageSpinner({ initiator: 'Amazon Pay: payment complete' }),
            sendAnalyticsEventAction({
              action: 'pay',
              category: 'amazon pay',
              label: 'payment complete',
              nonInteraction: true,
            }),
            updateBillingDemographicsAction({
              payload: {
                valid: true,
                firstName: returnOrDefaultString(response.payer_first_name, defaultBillingConfig.firstName, BillingDefaultValue.FirstName),
                lastName: returnOrDefaultString(response.payer_last_name, defaultBillingConfig.lastName, BillingDefaultValue.LastName),
                email: returnOrDefaultString(response.payer_email),
                phone: returnOrDefaultString(response.payer_phone_number),
                address1: returnOrDefaultString(response.billing_address?.addressLine1),
                address2: returnOrDefaultString(response.billing_address?.addressLine2),
                city: returnOrDefaultString(response.billing_address?.city),
                state: returnOrDefaultString(response.billing_address?.stateOrRegion),
                country: returnOrDefaultString(response.billing_address?.country),
                zip: returnOrDefaultString(response.billing_address?.zipCode),
              },
            }),
            gatherPaymentDetailsForAmazonPayAction({
              payload: paymentDetails,
            }),
          ];
        } else {
          const errorMsg = getErrorLocaleFromResponse(this.localeService, response);
          const initiator = 'Amazon Pay: payment failed error';

          return [
            sendAnalyticsEventAction({
              action: 'pay',
              category: 'amazon pay',
              label: 'payment failed',
              nonInteraction: true,
            }),
            sendPaymentFailed({
              payload: {
                paymentType: PaymentType.AMAZON_PAY,
                errorCode: response.error_code || response.title,
                reason: errorMsg,
              },
            }),
            hidePageSpinner({ initiator }),
            showNotificationAction({
              buttonLabel: this.localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator,
              message: errorMsg,
              onClose: () => this.store.dispatch(routeTo({ payload: ['select'] })),
            }),
          ];
        }
      })
    )
  );

  /**
   * @param actions$ The action stream.
   * @param amazonPayService Our main service.
   * @param store The store.
   */
  constructor(private store: Store<AppState>, private actions$: Actions, private amazonPayService: AmazonPayService, private localeService: LocaleService) {}
}
