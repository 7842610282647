import { DonationAuthRequest, InsuranceAuthRequest } from 'src/app/core/api/responses/authorize';
import { InitiateAltPayRequest, InitiateAltPayResponse } from 'src/app/core/api/responses/initiate-alt-pay';
import { UpdateAltPayResponse } from 'src/app/core/api/responses/update-alt-pay';
import { AuthorizedPaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { isAdyen } from 'src/app/shared/utilities/alternate-payment.utils';
import { buildBillinDemographics, formatDemographicsAsUserDataToCardHolder } from 'src/app/shared/utilities/demographics.utils';
import { convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';
import { validObject, validString } from 'src/app/shared/utilities/types.utils';
import { objectToQueryParams } from 'src/app/shared/utilities/url.utils';
import { PaymentProviderType } from './../../../core/payment-configuration/payment-configuration.model';
import { isCybersource } from './../../../shared/utilities/alternate-payment.utils';

export enum AlternatePaymentQueryParams {
  AUTH_ID = 'acsop_aid',
  LANGUAGE = 'acsop_ln',
  REDIRECT_URL = 'acsop_ul',
  PAYMENT_MERCHANT_ID = 'acsop_pid',
  PAYMENT_TYPE = 'acsop_pt',
  TENANT = 'acsop_tn',
  SESSION_TOKEN = 'acsop_t',
  SESSION_ID = 'acsop_i',
}

export interface AltPayRedirectDetails {
  paymentMerchantId: number;
  paymentMethodCode: PaymentMethodCode;
  paymentType: PaymentType;
  issuerId: string;
  encryptedToken: string;
  paymentProvider?: PaymentProviderType;
  donation?: DonationAuthRequest;
  insurance?: InsuranceAuthRequest;
}

export interface AlternatePaymentRedirectParams {
  MD: string;
  PaReq: string;
  TermUrl: string;
}

export interface AlternatePaymentRedirectPayload {
  amount?: number;
  authId: number;
  method: string;
  paymentReference: string;
  url: string;
  paymentMerchantId: number;
  paymentMethodCode: string;
  paymentProviderName: string;
  rawCardBrand: string;
  redirectParams?: AlternatePaymentRedirectParams;
}

export function hasValidRedirectParams(redirectParams: AlternatePaymentRedirectParams): boolean {
  return Object.values(redirectParams).every(validString);
}

/**
 * Check Initial Alt Pay response for QR data property and is a valid string
 * @param response InitialAltPay response
 */
export function hasValidQrData(response: InitiateAltPayResponse): boolean {
  const { qr_code_data, action } = response;
  const hasValidAdyenData = validObject(action) && ['paymentData', 'paymentMethodType', 'qrCodeData', 'type'].every((key) => validString(action[key]));

  return hasValidAdyenData || validString(qr_code_data);
}

/**
 * Check if Adyen's web component is required to render QR codes
 * @param provider PaymentProviderType
 * @param paymentMethodCode PaymentMethodCode
 */
export function isAdyenWebComponentRequired(provider: PaymentProviderType, paymentMethodCode: PaymentMethodCode): boolean {
  return isAdyen(provider) && [PaymentMethodCode.BANCONTACT_MOBILE, PaymentMethodCode.WECHAT_PAY].includes(paymentMethodCode);
}

/**
 * Check if a custom QR component is required to render QR data
 * @param paymentMethodCode PaymentMethodCode
 */
export function isCustomQrComponentRequired(paymentMethodCode: PaymentMethodCode): boolean {
  return paymentMethodCode === PaymentMethodCode.TTB_QR_CODE;
}

/**
 * Check if payment method redirection is required
 * @param paymentMethodCode PaymentMethodCode
 */
export function isPendingWithoutRedirect(paymentMethodCode: PaymentMethodCode): boolean {
  return [PaymentMethodCode.MB_WAY, PaymentMethodCode.SEPADD, PaymentMethodCode.TTB_QR_CODE].includes(paymentMethodCode);
}

/**
 * Returns complete initiate alternate payment request based on the payment method code
 * @param req initiate alt pay request
 * @param encryptedToken encrypted adyen token
 * @param provider payment provider details
 * @returns Initiate alternate payment request
 */
export function getCompleteInitiateAltPayRequest(
  paymentProvider: PaymentProviderType,
  req: InitiateAltPayRequest,
  encryptedToken: string,
  demographics
): InitiateAltPayRequest {
  const { AFTERPAY, BANCONTACT_DESKTOP, IDEAL, KAKAO_PAY, MB_WAY, SEPADD, SOFORT, KLARNA_PAY_NOW, KLARNA_PAY_OVER_TIME } = PaymentMethodCode;
  const { payment_type: paymentMethodCode } = req;
  const isAdyenPaymentProvider = isAdyen(paymentProvider);

  if (isAdyenPaymentProvider) {
    req.billingAddress = buildBillinDemographics(demographics);
  }

  switch (paymentMethodCode) {
    case BANCONTACT_DESKTOP:
      if (isAdyenPaymentProvider) {
        return { ...req, ...appendMetaDataToInitAltPayReq(demographics, { token_response: encryptedToken }) };
      }
      return { ...req, ...appendMetaDataToInitAltPayReq(demographics) };
    case MB_WAY:
      return { ...req, ...appendMetaDataToInitAltPayReq({ ...demographics }) };
    case SEPADD:
      return { ...req, iban_number: demographics.iban_number, sepa_account_holder_name: demographics.account_holder_name };
    case AFTERPAY:
    case IDEAL:
    case SOFORT:
      return isCybersource(paymentProvider) ? { ...req, ...appendMetaDataToInitAltPayReq(demographics) } : req;
    case KAKAO_PAY:
      if (isAdyenPaymentProvider) {
        return req;
      }
      return { ...req, ...appendMetaDataToInitAltPayReq(demographics) };
    case KLARNA_PAY_NOW:
    case KLARNA_PAY_OVER_TIME:
      return { ...req, ...appendMetaDataToInitAltPayReq(demographics) };
    default:
      return req;
  }
}

/**
 * Build JSON with additional fields for the initial alternate payment request
 * @param demographics demographics
 * @param card credit card information
 * @returns object with additional info for Initiate alternate payment request
 */
export function appendMetaDataToInitAltPayReq(demographics: Partial<DemographicsFormData>, card = null) {
  const address = validString(demographics.address1) ? `${demographics.address1} ${validString(demographics.address2) ? demographics.address2 : ''}` : '';

  const requestPayload = {
    CARDHOLDER: formatDemographicsAsUserDataToCardHolder(demographics),
    accept_header: 'text/html,application/xhtml+xml,application/xml;q=0.9,image/webp,image/apng,*/*;q=0.8',
    timezone_offset: new Date().getTimezoneOffset(),
  };

  if (card) {
    return {
      ...requestPayload,
      CARD: card,
    };
  }

  return requestPayload;
}

export function getRedirectResultEndpoint(domain: string, paymentProvder: PaymentProviderType) {
  return `https://${domain}/php/result` + (isAdyen(paymentProvder) ? '/adyen/' : '/altpayment/');
}

/**
 *
 * @param object
 * @returns
 */
export function getRedirectQueryParams({
  accessoPaySessionId,
  tenant,
  paymentProvider,
  paymentMethodCode,
  parentSessionId,
}: {
  accessoPaySessionId: string;
  tenant: string;
  paymentProvider: PaymentProviderType;
  paymentMethodCode: PaymentMethodCode;
  parentSessionId: string;
}): string {
  const paramsObj = {
    [AlternatePaymentQueryParams.SESSION_TOKEN]: accessoPaySessionId,
    [AlternatePaymentQueryParams.TENANT]: tenant,
    paymentMethodCode,
  };

  if (isAdyen(paymentProvider)) {
    // use query params for redirecting instead of using accessoPay sessions to retrieve redirect url
    paramsObj[AlternatePaymentQueryParams.SESSION_ID] = parentSessionId;
  }

  const queryParams = objectToQueryParams(paramsObj);

  return queryParams;
}

/**
 * Maps authoirzation details for the sendPaymentComplete action
 * @param response InitiateAltPay response
 * @param paymentDetails payment config detail for payment method
 * @returns the partial payload for payment complete event
 */
export const mapAltPayAuthResponseToPaymentComplete = (
  response: InitiateAltPayResponse | UpdateAltPayResponse,
  paymentDetails: Partial<AltPayRedirectDetails>
): AuthorizedPaymentInfo => {
  const { paymentType, paymentMerchantId, paymentProvider } = paymentDetails;
  const paymentInfo: AuthorizedPaymentInfo = {
    authId: response.paymentReference,
    method: paymentType,
    approval_code: response.result_code,
    legacy: {
      authId: response.auth_id.toString(),
      paymentMerchantId: paymentMerchantId,
      paymentMethodCode: response.payment_type,
      rawCardBrand: response.payment_type,
      paymentProviderName: paymentProvider,
    },
  };

  if (validObject(response.donation)) {
    paymentInfo.donation = convertDonationResponse(response.donation);
  }
  if (validObject(response.insurance)) {
    paymentInfo.insurance = convertInsuranceResponse(response.insurance);
  }

  return paymentInfo;
};
