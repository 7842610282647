import { Inject, Injectable } from '@angular/core';
import { Store, createSelector, select } from '@ngrx/store';
import { get, isNumber } from 'lodash-es';
import { Observable } from 'rxjs';
import { catchError, filter, switchMap, take } from 'rxjs/operators';
import { AppState } from 'src/app/app.state';
import { AnalyticsCheckoutStep, sendAnalyticsEventAction, setCheckoutStepAction } from 'src/app/core/analytics/analytics.actions';
import { ApiStatus } from 'src/app/core/api/api.interface';
import { ApiService } from 'src/app/core/api/api.service';
import { CreatePayPalBillingAgreementRequest, CreatePayPalBillingAgreementResponse } from 'src/app/core/api/responses/create-paypal-billing-agreement';
import {
  CreatePayPalBillingAgreementOrderRequest,
  CreatePayPalBillingAgreementOrderResponse,
} from 'src/app/core/api/responses/create-paypal-billing-agreement-order';
import { CreatePayPalOrderRequest, CreatePayPalOrderResponse, PayPalOrderStatus } from 'src/app/core/api/responses/create-paypal-order';
import { UpdatePayPalStatusResponse } from 'src/app/core/api/responses/update-paypal-status';
import { sendMessageAction } from 'src/app/core/application-bridge/application-bridge.actions';
import { AuthorizedPaymentInfo, PaymentType } from 'src/app/core/application-bridge/application-bridge.models';
import { selectAppConfig } from 'src/app/core/application-config/application-config.selectors';
import { PayPalApplicationConfig, PayPalFundingSourceStyle } from 'src/app/core/application-config/application-config.state';
import { selectClientConfiguration } from 'src/app/core/client-configuration/client-configuration.selectors';
import { ClientConfigurationState } from 'src/app/core/client-configuration/client-configuration.state';
import { DOCUMENT } from 'src/app/core/injection-token/document/document';
import { WINDOW } from 'src/app/core/injection-token/window/window';
import { LocaleService } from 'src/app/core/locale/locale.service';
import { AcLoggerService } from 'src/app/core/logger/logger.service';
import { NotificationDialogType } from 'src/app/core/notification/dialog/confirm-dialog/confirm-dialog.component';
import { showNotificationAction } from 'src/app/core/notification/notification.actions';
import { hidePageSpinner, showPageSpinner } from 'src/app/core/page-spinner/page-spinner.actions';
import { selectParentConfig } from 'src/app/core/parent-config/parent-config.selectors';
import { ParentConfigState } from 'src/app/core/parent-config/parent-config.state';
import { getLineItemsAndFees } from 'src/app/core/parent-config/parent-config.utils';
import { AlternatePayment, ConfiguredPayments, PaymentMethodCode } from 'src/app/core/payment-configuration/payment-configuration.model';
import { selectConfiguredPayments } from 'src/app/core/payment-configuration/payment-configuration.selectors';
import { ApiRequestType } from 'src/app/shared/enums/api.enums';
import { EVENT_PAYMENT_CANCELLED, EVENT_PAYMENT_FAILED, EVENT_PAYMENT_SELECTED } from 'src/app/shared/enums/application-bridge.enums';
import { APP_CONFIG_PAYPAL } from 'src/app/shared/enums/application-config.enum';
import { PayPalFundingSource } from 'src/app/shared/enums/billing.enums';
import { selectPaymentAmount, selectTotalAmount } from 'src/app/shared/selectors/configuration.selectors';
import { calculateSplitAmountWithDonation, convertDonationResponse } from 'src/app/shared/utilities/donation.utils';
import { calculateSplitAmountWithInsurance, convertInsuranceResponse } from 'src/app/shared/utilities/insurance.utils';
import { toObservable } from 'src/app/shared/utilities/observable.utils';
import { addScript } from 'src/app/shared/utilities/script-loader.utils';
import { normalize, returnOrDefaultString } from 'src/app/shared/utilities/string.utils';
import { validArray, validObject, validString } from 'src/app/shared/utilities/types.utils';
import { objectToQueryParams } from 'src/app/shared/utilities/url.utils';
import { selectDelivery } from '../../delivery/delivery.selectors';
import { DeliveryState } from '../../delivery/delivery.state';
import { selectDonation, selectTicketProtection } from '../../extras/extras.selectors';
import { DonationState, TicketProtectionState } from '../../extras/extras.state';
import { selectGiftCardState } from '../../gift-card/gift-card.selectors';
import { GiftCardState } from '../../gift-card/gift-card.state';
import { hasPreAuthGiftCards } from '../../gift-card/gift-card.utils';
import { gatherPaymentDetailsForPayPalAction, sendPaymentFailed, updateBillingDemographicsAction, updatePayPalAvailabilityAction } from '../billing.actions';
import { initializeGiftCardSplitPaymentWithPayPalAction } from './paypal.actions';

const ERROR_PREFIX = '[PayPalService] ';
@Injectable({
  providedIn: 'root',
})
export class PayPalService {
  /**
   * The configured payments for merchant
   */
  private configuredPayments: ConfiguredPayments;
  /**
   * The parent configuration
   */
  private parentConfig: ParentConfigState;
  /**
   * The delivery state
   */
  private deliveryState: DeliveryState;
  /**
   * Donation State
   */
  private donationState: DonationState;
  /**
   * The auth id for the current transaction
   */
  private authId: number;
  /**
   * The selected app configs.
   */
  private appConfigs: {
    paypal?: PayPalApplicationConfig;
  };
  /**
   * Our client configuration
   */
  private clientConfiguration: ClientConfigurationState;
  /**
   * The amount to be paid
   */
  private paymentAmount: number;
  /**
   * The gift card state
   */
  private giftCardState: GiftCardState;
  /**
   * The total amount state
   */
  private totalAmount: number;
  /**
   * Ticket insurance state
   */
  private insurance: TicketProtectionState;
  /**
   * The auth id for the current transaction
   */
  private paymentReference: string;

  /**
   * Constructor
   * @param window window
   * @param document document
   * @param store our store
   * @param apiService ApiService
   * @param logger AcLoggerService
   * @param localeService AcLoggerService
   */
  constructor(
    @Inject(WINDOW) private window: Window,
    @Inject(DOCUMENT) private document: Document,
    private store: Store<AppState>,
    private apiService: ApiService,
    private logger: AcLoggerService,
    private localeService: LocaleService
  ) {
    this.store
      .pipe(
        select(
          createSelector(
            selectConfiguredPayments,
            selectParentConfig,
            selectAppConfig([APP_CONFIG_PAYPAL]),
            selectDelivery,
            selectClientConfiguration,
            selectPaymentAmount,
            selectGiftCardState,
            selectDonation,
            selectTotalAmount,
            selectTicketProtection,
            (
              configuredPayments,
              parentConfig,
              appConfigs,
              deliveryState,
              clientConfiguration,
              paymentAmount,
              giftCardState,
              donation,
              totalAmount,
              ticketProtection
            ) => ({
              configuredPayments,
              parentConfig,
              appConfigs,
              deliveryState,
              clientConfiguration,
              paymentAmount,
              giftCardState,
              donation,
              totalAmount,
              ticketProtection,
            })
          )
        )
      )
      .subscribe(
        ({
          configuredPayments,
          parentConfig,
          appConfigs,
          deliveryState,
          clientConfiguration,
          paymentAmount,
          giftCardState,
          donation,
          totalAmount,
          ticketProtection,
        }) => {
          this.configuredPayments = configuredPayments;
          this.parentConfig = parentConfig;
          this.appConfigs = appConfigs;
          this.deliveryState = deliveryState;
          this.donationState = donation;
          this.clientConfiguration = clientConfiguration;
          this.paymentAmount = paymentAmount;
          this.giftCardState = giftCardState;
          this.totalAmount = totalAmount;
          this.insurance = ticketProtection;
        }
      );
  }

  /**
   * Loads paypal script if configured
   */
  loadPayPal(): Observable<boolean> {
    if (!!this.getPayPalPaymentConfiguration()) {
      const paypalScript = this.getPayPalScript();
      return addScript(paypalScript, false, { id: 'ap-paypal' }).pipe(
        switchMap((_) => toObservable(true)),
        catchError((_) => {
          this.store.dispatch(updatePayPalAvailabilityAction({ status: false }));
          return toObservable(true);
        })
      );
    }
    return toObservable(true);
  }

  /**
   * Return PayPal SDK script to load
   * @returns paypal script
   */
  private getPayPalScript(): string {
    const paypalConfiguration = this.getPayPalConfiguration();
    if (validObject(paypalConfiguration)) {
      return `https://www.paypal.com/sdk/js?${objectToQueryParams(paypalConfiguration)}`;
    }
    return '';
  }

  /**
   * Return PayPal configuration for the SDK
   * @returns paypal configuration
   */
  private getPayPalConfiguration(): AP_PayPalScriptQueryParameters {
    const currency = returnOrDefaultString(this.parentConfig?.config?.currencyCode, this.appConfigs?.paypal?.currencyCode, 'USD');
    if (!validString(this.appConfigs?.paypal?.clientId)) {
      this.store.dispatch(
        showNotificationAction({
          buttonLabel: this.localeService.get('common.close'),
          dialogType: NotificationDialogType.GENERAL,
          initiator: 'PayPal Client Id Missing',
          message: this.localeService.get('paypal.setupfailure'),
        })
      );
      return null;
    }
    const config: AP_PayPalScriptQueryParameters = {
      'client-id': this.appConfigs.paypal.clientId,
      currency,
      intent: this.parentConfig?.config?.recurring ? 'tokenize' : 'capture',
      components: 'buttons,funding-eligibility',
      vault: this.parentConfig?.config?.recurring || false,
    };

    if (validString(this.appConfigs.paypal?.locale)) {
      config.locale = this.appConfigs.paypal.locale;
    }

    if (
      validArray(this.appConfigs.paypal?.fundingSources) &&
      this.appConfigs.paypal.fundingSources.some((fundingSource) => fundingSource.source === PayPalFundingSource.VENMO)
    ) {
      config['enable-funding'] = PayPalFundingSource.VENMO;
    }

    return config;
  }

  /**
   * Returns the paypal configuration on the merchant
   * @returns paypal configuration
   */
  private getPayPalPaymentConfiguration(): AlternatePayment {
    return this.configuredPayments.alternatePayments?.find((altPayment) => altPayment.paymentMethodCode === PaymentMethodCode.PAYPAL);
  }

  /**
   * Configures and mounts PayPal Smart Buttons on the element id passed
   * @param id element id
   */
  configurePayPal(id: string): void {
    if (!validString(id)) {
      return;
    }

    const el = this.document.getElementById(id);

    if (!el) {
      setTimeout(() => {
        this.configurePayPal(id);
      }, 500);
    }

    const isRecurring = this.parentConfig?.config?.recurring || false;
    const paypal: AP_PayPalNamespace = get(this.window, 'paypal');
    const fundingSources = this.getAvailableFundingSources();

    let fundingSourceCount = 0;

    fundingSources.forEach((fundingSource) => {
      // max 3 funding sources are accepted
      if (fundingSourceCount >= 3) {
        return;
      }
      const buttonConfiguration: AP_PayPalButtonsComponentOptions = {
        fundingSource,
        onError: (
          error:
            | Record<string, unknown>
            | CreatePayPalOrderResponse
            | CreatePayPalBillingAgreementResponse
            | CreatePayPalBillingAgreementOrderResponse
            | UpdatePayPalStatusResponse
        ) => {
          this.logger.error(`${ERROR_PREFIX} ${validString(error?.message) ? error.message : JSON.stringify(error)}`);
          let message: string;
          if (error?.error_code) {
            message = this.localeService.get(`errorcode.${error.error_code}`);
          } else if (error?.error_msg) {
            message = error.error_msg as string;
          } else {
            message = this.localeService.get('paypal.internalerror');
          }

          [
            sendPaymentFailed({
              payload: {
                paymentType: PaymentType.PAYPAL,
                details: `${error?.error_msg ?? error?.message ?? message}`,
                errorCode: `${error?.error_code}`,
              },
            }),
            showNotificationAction({
              buttonLabel: this.localeService.get('common.close'),
              dialogType: NotificationDialogType.GENERAL,
              initiator: 'PayPal Error',
              message,
            }),
          ].forEach((action) => this.store.dispatch(action));
        },
        onCancel: (data: Record<string, unknown>, actions: AP_OnCancelledActions) => {
          [
            sendAnalyticsEventAction({
              action: 'click',
              category: PaymentType.PAYPAL,
              label: 'payment canceled',
            }),
            sendMessageAction({ key: EVENT_PAYMENT_CANCELLED }),
          ].forEach((action) => this.store.dispatch(action));
        },
        onClick: (e) => {
          [
            setCheckoutStepAction({
              step: AnalyticsCheckoutStep.SELECT_PAYMENT,
              option: PaymentType.PAYPAL,
            }),
            sendAnalyticsEventAction({
              action: 'click',
              category: 'payment selection',
              label: `${validString(e?.fundingSource) ? e.fundingSource : PaymentType.PAYPAL}`,
            }),
            sendMessageAction({
              key: EVENT_PAYMENT_SELECTED,
              payload: {
                type: PaymentType.PAYPAL,
              },
            }),
          ].forEach((action) => this.store.dispatch(action));
        },
      };
      if (isRecurring) {
        buttonConfiguration.createBillingAgreement = () => this.createBillingAgreement();
        buttonConfiguration.onApprove = (data: AP_OnApproveData, actions: AP_OnApproveActions) => this.createBillingAgreementOrder(data.billingToken);
      } else {
        buttonConfiguration.createOrder = (data: Record<string, unknown>, actions: AP_CreateOrderActions) => this.createPayPalOrder();
        buttonConfiguration.onApprove = (data: AP_OnApproveData, actions: AP_OnApproveActions) => this.updatePayPalStatus();
      }

      buttonConfiguration.style = {
        ...this.returnConfiguredFundingSourceStyle(fundingSource),
        height: 46,
        shape: 'rect',
        tagline: false,
        layout: 'vertical',
        size: 'responsive',
        borderRadius: 8,
      } as AP_PayPalButtonStyles;

      const paypalButton = paypal.Buttons(buttonConfiguration);

      if (paypalButton.isEligible()) {
        fundingSourceCount += 1;
        paypalButton.render(`#${id}`);
      }
    });

    if (fundingSourceCount > 1) {
      el.className += fundingSourceCount > 2 ? ' select--paypal-funding-extra' : ' select--paypal-funding';
    } else {
      el.className += ' select--paypal';
    }

    // append any additional styles configured
    const additionalStyles = this.appConfigs.paypal?.additionalStyles;
    if (validObject(this.appConfigs.paypal?.additionalStyles)) {
      Object.entries(additionalStyles).forEach(([key, val]) => {
        if (validString(key) && validString(val)) {
          el.style[key] = val;
        }
      });
    }
  }

  /**
   * Calls Payment Service to create PayPal Order
   * @returns paypal order id
   */
  private async createPayPalOrder(): Promise<CreatePayPalOrderResponse | string> {
    this.store.dispatch(showPageSpinner({ initiator: 'PayPal Create Order' }));
    const discounts = (await this.initializeGiftCardSplitPaymentProcess()) as string[];
    const paypalOrderRequest = this.getPayPalOrderRequest(discounts);
    const data = await this.apiService.post<CreatePayPalOrderResponse>(ApiRequestType.CREATE_PAYPAL_ORDER, paypalOrderRequest).toPromise();
    const { status, response } = data;
    this.store.dispatch(hidePageSpinner({ initiator: 'PayPal Order Created' }));
    if (status !== ApiStatus.OK) {
      return Promise.reject(response);
    }
    this.setAuthId(response.auth_id);
    this.setPaymentReference(response.paymentReference);
    return response.paypal_order_id;
  }

  /**
   * Calls Payment Service to create Billing Agreement
   * @returns paypal order id
   */
  private async createBillingAgreement(): Promise<CreatePayPalBillingAgreementResponse | string> {
    this.store.dispatch(showPageSpinner({ initiator: 'PayPal Create Billing Agreement' }));
    const paypalBillingAgreeementRequest = this.getPayPalBillingAgreementRequest();
    const data = await this.apiService
      .post<CreatePayPalBillingAgreementResponse>(ApiRequestType.CREATE_PAYPAL_BILLING_AGREEMENT, paypalBillingAgreeementRequest)
      .toPromise();
    const { status, response } = data;
    this.store.dispatch(hidePageSpinner({ initiator: 'PayPal Billing Agreement Created' }));
    if (status !== ApiStatus.OK) {
      return Promise.reject(response);
    }
    return response.token_id;
  }

  /**
   * Initializes gift card split payment process
   * @returns discounts payment reference
   */
  private initializeGiftCardSplitPaymentProcess(): Promise<string[]> {
    return new Promise((resolve) => {
      if (this.giftCardState.totalBalance > 0) {
        if (!hasPreAuthGiftCards(this.giftCardState.giftcards)) {
          this.store.dispatch(initializeGiftCardSplitPaymentWithPayPalAction({ amount: this.totalAmount, payload: {} }));
        }

        this.store
          .pipe(
            select(selectGiftCardState),
            filter((state) => validArray(state.giftcards) && state.giftcards.every((gc) => validString(gc.paymentReference))),
            take(1)
          )
          .subscribe(({ giftcards }) => resolve(giftcards.map((gc) => gc.paymentReference)));
      } else {
        return resolve([]);
      }
    });
  }

  /**
   * Calls Payment Service to create Billing Agreement Order
   * @param billingToken billing agreement token
   * @returns paypal order id
   */
  private async createBillingAgreementOrder(billingToken: string): Promise<CreatePayPalBillingAgreementOrderResponse | undefined> {
    const discounts = (await this.initializeGiftCardSplitPaymentProcess()) as string[];
    const paypalBillingAgreeementRequest: CreatePayPalBillingAgreementOrderRequest = {
      ...this.getPayPalOrderRequest(discounts),
      billing_agreement_token: billingToken,
    };
    this.store.dispatch(showPageSpinner({ initiator: 'PayPal Create Billing Agreement Order' }));

    const data = await this.apiService
      .post<CreatePayPalBillingAgreementOrderResponse>(ApiRequestType.CREATE_PAYPAL_BILLING_AGREEMENT_ORDER, paypalBillingAgreeementRequest)
      .toPromise();
    const { status, response } = data;
    const paypalPaymentConfiguration = this.getPayPalPaymentConfiguration();

    if (status !== ApiStatus.OK) {
      [
        hidePageSpinner({ initiator: 'PayPal Create Billing Agreement Order Failed' }),
        sendAnalyticsEventAction({
          action: 'pay',
          category: 'paypal',
          label: 'payment failed',
          nonInteraction: true,
        }),
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {
            errorCode: response.error_code,
            reason: response.error_msg,
          },
        }),
      ].forEach((action) => this.store.dispatch(action));
      return Promise.reject(response);
    }

    const payload: AuthorizedPaymentInfo = {
      method: PaymentType.PAYPAL,
      authId: response.paymentReference,
      legacy: {
        authId: response.auth_id,
        paymentMerchantId: paypalPaymentConfiguration.paymentMerchantId,
        paymentMethodCode: paypalPaymentConfiguration.paymentMethodCode,
        paymentProviderName: paypalPaymentConfiguration.paymentProviderName,
        rawCardBrand: paypalPaymentConfiguration.rawCardBrand,
      },
      ...(validObject(response.donation) && { donation: convertDonationResponse(response.donation) }),
      ...(validObject(response.insurance) && { insurance: convertInsuranceResponse(response.insurance) }),
    };

    const billingAddressPayload: Partial<DemographicsFormData> = {
      valid: true,
      email: response.payer_email,
      phone: returnOrDefaultString(response.payer_phone_number),
      firstName: returnOrDefaultString(response.payer_first_name),
      lastName: returnOrDefaultString(response.payer_last_name),
    };

    if (validObject(response.billing_address)) {
      const { addressLine1, addressLine2, city, country, state, zipCode } = response.billing_address;
      billingAddressPayload.address1 = returnOrDefaultString(addressLine1);
      billingAddressPayload.address2 = returnOrDefaultString(addressLine2);
      billingAddressPayload.city = returnOrDefaultString(city);
      billingAddressPayload.country = returnOrDefaultString(country);
      billingAddressPayload.state = returnOrDefaultString(state);
      billingAddressPayload.zip = returnOrDefaultString(zipCode);
    }

    [
      hidePageSpinner({ initiator: 'PayPal Create Billing Agreement Order Success' }),
      sendAnalyticsEventAction({
        action: 'pay',
        category: 'paypal',
        label: 'payment complete',
        nonInteraction: true,
      }),
      updateBillingDemographicsAction({ payload: billingAddressPayload }),
      gatherPaymentDetailsForPayPalAction({ payload }),
    ].forEach((action) => this.store.dispatch(action));
  }

  /**
   * Update PayPal status to Payment Service
   * Called after user authorizes payment on PayPal
   */
  private async updatePayPalStatus(): Promise<UpdatePayPalStatusResponse | undefined> {
    const paypalPaymentConfiguration = this.getPayPalPaymentConfiguration();
    const authId = this.getAuthId();

    if (!isNumber(authId)) {
      return Promise.reject({
        error_msg: this.localeService.get('paypal.internalerror'),
      });
    }

    this.store.dispatch(showPageSpinner({ initiator: 'PayPal Update Status' }));

    const data = await this.apiService
      .post<UpdatePayPalStatusResponse>(ApiRequestType.UPDATE_PAYPAL_STATUS, {
        payment_reference: this.getPaymentReference(),
        req_billing_address: 1,
      })
      .toPromise();
    const { status, response } = data;

    if (status !== ApiStatus.OK) {
      [
        hidePageSpinner({ initiator: 'PayPal Update Status Failed' }),
        sendAnalyticsEventAction({
          action: 'pay',
          category: 'paypal',
          label: 'payment failed',
          nonInteraction: true,
        }),
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {
            errorCode: response.error_code,
            reason: response.error_msg,
          },
        }),
      ].forEach((action) => this.store.dispatch(action));
      return Promise.reject(response);
    }

    const payload: AuthorizedPaymentInfo = {
      method: PaymentType.PAYPAL,
      authId: response.paymentReference,
      legacy: {
        authId: response.auth_id,
        paymentMerchantId: paypalPaymentConfiguration.paymentMerchantId,
        paymentMethodCode: paypalPaymentConfiguration.paymentMethodCode,
        paymentProviderName: paypalPaymentConfiguration.paymentProviderName,
        rawCardBrand: paypalPaymentConfiguration.rawCardBrand,
      },
      lastFour: response.card_last_four,
      cardType: response.card_type_code,
    };

    if (validObject(response.donation)) {
      payload.donation = convertDonationResponse(response.donation);
    }
    if (validObject(response?.insurance)) {
      payload.insurance = convertInsuranceResponse(response.insurance);
    }

    const billingAddressPayload: Partial<DemographicsFormData> = {
      valid: true,
      email: response.payer_email,
      phone: returnOrDefaultString(response.payer_phone_number),
      firstName: returnOrDefaultString(response.payer_first_name),
      lastName: returnOrDefaultString(response.payer_last_name),
    };

    if (validObject(response.billing_address)) {
      const { addressLine1, addressLine2, city, country, state, zipCode } = response.billing_address;
      billingAddressPayload.address1 = returnOrDefaultString(addressLine1);
      billingAddressPayload.address2 = returnOrDefaultString(addressLine2);
      billingAddressPayload.city = returnOrDefaultString(city);
      billingAddressPayload.country = returnOrDefaultString(country);
      billingAddressPayload.state = returnOrDefaultString(state);
      billingAddressPayload.zip = returnOrDefaultString(zipCode);
    }

    if (response.paypal_order_status === PayPalOrderStatus.APPROVED) {
      [
        hidePageSpinner({ initiator: 'PayPal Update Status Complete' }),
        sendAnalyticsEventAction({
          action: 'pay',
          category: 'paypal',
          label: 'payment complete',
          nonInteraction: true,
        }),
        updateBillingDemographicsAction({ payload: billingAddressPayload }),
        gatherPaymentDetailsForPayPalAction({ payload }),
      ].forEach((action) => this.store.dispatch(action));
    } else {
      [
        sendMessageAction({
          key: EVENT_PAYMENT_FAILED,
          payload: {},
        }),
        hidePageSpinner({ initiator: 'PayPal Update Status Complete' }),
        showNotificationAction({
          buttonLabel: this.localeService.get('common.close'),
          dialogType: NotificationDialogType.GENERAL,
          initiator: 'PayPal Unexpected status',
          message: this.localeService.get('paypal.invalidorderstatus'),
        }),
      ].forEach((action) => this.store.dispatch(action));
    }
  }

  /**
   * Sets auth id created from create order response
   * @param authId auth id
   */
  private setAuthId(authId: number): void {
    if (isNumber(authId)) {
      this.authId = authId;
    }
  }

  /**
   * Returns the auth id set from create order request
   * @returns auth id for current transaction
   */
  getAuthId(): number {
    return this.authId;
  }

  /**
   * Sets payment reference created from create order response
   * @param authId auth id
   */
  private setPaymentReference(paymentReference: string): void {
    if (validString(paymentReference)) {
      this.paymentReference = paymentReference;
    }
  }

  /**
   * Returns payment reference from create order request
   * @returns auth id for current transaction
   */
  getPaymentReference(): string {
    return this.paymentReference;
  }

  /**
   * Returns the smart button styles for the given funding source
   * @param fundingSource paypal funding source
   * @returns button styles
   */
  private returnConfiguredFundingSourceStyle(source: string): PayPalFundingSourceStyle {
    const paypal: AP_PayPalNamespace = get(this.window, 'paypal');
    const paypalSourceStyle = this.getFundingSourceConfiguredStyle(source) || {};
    let paypalDefaultColor = 'gold';

    if (source === paypal.FUNDING.VENMO) {
      paypalDefaultColor = 'blue';
    } else if (source === paypal.FUNDING.CARD) {
      paypalDefaultColor = 'black';
    } else if (source === paypal.FUNDING.CREDIT) {
      paypalDefaultColor = 'darkblue';
    }

    return {
      color: returnOrDefaultString(paypalSourceStyle.color, paypalDefaultColor),
      label: returnOrDefaultString(paypalSourceStyle.label, 'checkout'),
      period: paypalSourceStyle.period || null,
    };
  }

  /**
   * Returns the configured style for the paypal funding source
   * @param source paypal funding source
   * @returns configured style for funding source
   */
  private getFundingSourceConfiguredStyle(source: string): Partial<PayPalFundingSourceStyle> {
    if (!validArray(this.appConfigs?.paypal?.fundingSources)) {
      return null;
    }
    const configuredStyle = this.appConfigs.paypal.fundingSources.find((fundingSource) => fundingSource.source === this.getFundingSourcesName(source));
    if (configuredStyle && validObject(configuredStyle.style)) {
      return configuredStyle.style;
    }
  }

  /**
   * Returns the funding sources configured
   * @returns paypal founding sources
   */
  private getAvailableFundingSources(): string[] {
    const configuredFundingSources = validArray(this.appConfigs.paypal?.fundingSources)
      ? this.appConfigs.paypal.fundingSources.map((fundingSources) => fundingSources.source)
      : ['paypal'];

    return configuredFundingSources.map(this.getFundingSourcesName, this);
  }

  /**
   * Returns funding source name on paypal
   * @param name configured founding source
   * @returns funding source on paypal
   */
  private getFundingSourcesName(name: string): string {
    const paypal: AP_PayPalNamespace = get(this.window, 'paypal');
    const paypalFunding = paypal.FUNDING;
    switch (normalize(name)) {
      case 'paypal':
        return paypalFunding.PAYPAL;
      case 'venmo':
        return paypalFunding.VENMO;
      case 'paylater':
        return paypalFunding.PAYLATER;
      case 'credit':
        return paypalFunding.CREDIT;
      case 'card':
        return paypalFunding.CARD;
      default:
        return paypalFunding.PAYPAL;
    }
  }

  /**
   * Returns the request object to create a paypal order for Payment Service
   * @param discountPaymentReferences payment references that provide discount
   * @returns Paypal create order request
   */
  private getPayPalOrderRequest(discountPaymentReferences: string[] = []): CreatePayPalOrderRequest {
    const { deliveryAmount } = this.deliveryState;
    const { cartItems, fees } = getLineItemsAndFees(this.parentConfig);
    const hasShippingAddress = this.deliveryState.deliveryMethodsConfigured && this.deliveryState.collectAddress;
    const shippingAddress = hasShippingAddress ? this.deliveryState.address : ({ valid: false } as DemographicsFormData);
    cartItems.forEach((item) => delete item.feeAmount);
    let totalAmount = this.paymentAmount;
    const { insurance, amount: amountAfterInsurance } = calculateSplitAmountWithInsurance(totalAmount, this.insurance);
    totalAmount = amountAfterInsurance;
    const { donation, amount } = calculateSplitAmountWithDonation(totalAmount, this.donationState);

    const reqObj: CreatePayPalOrderRequest = {
      merchant_id: this.getPayPalPaymentConfiguration().paymentMerchantId,
      amount,
      cartItems,
      fees,
      discount_payment_reference: discountPaymentReferences,
      shipping_total: deliveryAmount,
      no_shipping_flag: hasShippingAddress ? 0 : 1,
      address_override_flag: hasShippingAddress ? 1 : 0,
      ...(donation && { donation }),
      ...(insurance && { insurance }),
    };

    if (hasShippingAddress && shippingAddress.valid) {
      reqObj.shipping_address = {
        addressLine1: shippingAddress.address1 + (validString(shippingAddress.address2) ? ' ' + shippingAddress.address2 : ''),
        city: shippingAddress.city,
        country: shippingAddress.country,
        state: shippingAddress.state,
        zipCode: shippingAddress.zip,
      };
      reqObj.ship_to_name = `${shippingAddress.firstName} ${shippingAddress.lastName}`;
    }

    return reqObj;
  }

  /**
   * Get the window object
   * @returns The window object
   */
  getWindow(): Window {
    return this.window;
  }

  /**
   * Returns the request object to create a paypal billing agreement for Payment Service
   * @returns PayPal create billing agreement request
   */
  private getPayPalBillingAgreementRequest(): CreatePayPalBillingAgreementRequest {
    const hasShippingAddress = this.deliveryState.deliveryMethodsConfigured && this.deliveryState.collectAddress;
    const shippingAddress = hasShippingAddress ? this.deliveryState.address : ({ valid: false } as DemographicsFormData);
    const { language, merchantId, tenantName } = this.clientConfiguration;
    const url = `https://${location.host}/${tenantName}/${merchantId}/${language}/select`;

    const reqObj: CreatePayPalBillingAgreementRequest = {
      merchant_id: this.getPayPalPaymentConfiguration().paymentMerchantId,
      description: this.localeService.get('paypal.billingagreement'),
      skip_shipping_address: hasShippingAddress ? 0 : 1,
      return_url: url,
      cancel_url: url,
    };

    if (hasShippingAddress && shippingAddress.valid) {
      reqObj.shipping_address = {
        addressLine1: shippingAddress.address1 + (validString(shippingAddress.address2) ? ' ' + shippingAddress.address2 : ''),
        city: shippingAddress.city,
        country: shippingAddress.country,
        state: shippingAddress.state,
        zipCode: shippingAddress.zip,
      };
      reqObj.ship_to_name = `${shippingAddress.firstName} ${shippingAddress.lastName}`;
      reqObj.immutable_shipping_address = 1;
    }

    return reqObj;
  }
}
